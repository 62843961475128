<ip-email-builder
  *ngIf="editorEnabled; else codeEnabled"
  style="height: calc(100vh - 80px)"
  [email]="currentEmail"
></ip-email-builder>
<ng-template #codeEnabled>
  <mat-toolbar fxLayoutGap="1rem" fxLayoutAlign="space-between center">
    <ng-content
      fxLayout
      fxLayoutGap="0.5rem"
      select=".top-actions"
    ></ng-content>
    <div fxFlexlayout fxLayoutGap="0.5rem">
      <button
        type="button"
        (click)="saveEmail()"
        mat-button
        mat-stroked-button
        color="primary"
      >
        Enregistrer
      </button>
      <a [href]="getCampaignUrl()" target="_blank" mat-stroked-button>
        <span>Aperçu en ligne</span>
      </a>
    </div>
  </mat-toolbar>
  <div class="flex h-100">
    <div class="flex-1" style="height: 500px">
      <ngx-monaco-editor
        style="height: 100%"
        [options]="editorOptions"
        [(ngModel)]="code"
        (ngModelChange)="updateCode($event)"
      ></ngx-monaco-editor>
    </div>
    <div class="flex-1 preview-container">
      <iframe
        *ngIf="campaignContent && campaignContent.html"
        #renderedHTML
        [src]="htmlSource$ | async"
      ></iframe>
    </div>
  </div>
</ng-template>
