import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ButtonBlock,
  IEmailAndGoogleFonts,
  IIPEmail,
  IMjmlServerResponse,
  IUserModule,
  IUserTemplate,
  IUserTemplateCategory,
  ImageBlock,
  IpUserRestApiService,
  Structure,
  TextBlock,
  deferOf,
} from 'ip-email-builder';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../environments/environment';

const NGB_HOST = 'https://ngb-api.wlocalhost.org/v1';

@Injectable()
export class UserMediaRestApiService implements IpUserRestApiService {
  public token!: string;
  public forms$ = new BehaviorSubject<any[]>([]);
  public fields$ = new BehaviorSubject<string[]>([]);

  /**
   * Get all user merge fields to inject into text editor.
   */
  getAllUserMergeFields$: Observable<string[]> = combineLatest([
    this.fields$,
  ]).pipe(
    map(([tags]) => {
      return tags;
    })
  );

  /**
   * Get all Custom Modules to be included into Modules List.
   * We're looking for someone who could create many modules to be included by default, if you feel you can help us, please leave a message!
   */
  getAllUserModules$: Observable<IUserModule[]> = this.forms$
    .pipe(
      map(forms => this.getModules(forms))
    )

  /**
   * Get all Predefined Templates and categories.
   * You can leave it to get all default templates from NGB API
   */
  getAllUserTemplates$: Observable<IUserTemplateCategory[]> = this.http
    .get<{ category: string; templates: string[] }[]>(
      NGB_HOST.concat('/templates')
    )
    .pipe(
      map((list) =>
        list.map<IUserTemplateCategory>(({ category, templates }) => ({
          category,
          templates: templates.map((title) => ({
            thumbPath: `https://ngb-templates.s3.amazonaws.com/${category}-${title}.jpg`,
            title,
            templateData: (null as unknown) as IIPEmail,
          })),
        }))
      ),
      shareReplay()
    );

  public constructor(public http: HttpClient) { }

  /**
   * This method must return the chosen template from Template Gallery List IPEmail object,
   * see {@link IpUserRestApiService#getAllUserTemplates$|getAllUserTemplates$}.
   *
   * Sometimes it can contain 'templateData' property - in this case, just return it.
   *
   * @param category Selected Category from Template Gallery.
   * @param template Selected Template from Template Gallery.
   *
   * @example
   * if (template.templateData) { return template }
   */
  getUserTemplateData$(
    category: string,
    template: IUserTemplate
  ): Observable<IUserTemplate> {
    if (template.templateData) {
      return deferOf(template);
    }
    return this.http
      .get<IIPEmail>(NGB_HOST.concat('/templates'), {
        params: { category, template: template.title },
      })
      .pipe(map((email) => ({ ...template, templateData: email })));
  }

  userImageUpload$(
    body: FormData,
    uploadImagePath: string
  ): Observable<
    HttpEvent<{ success: boolean; path: string; message?: string | undefined }>
  > {
    return this.http.request(
      'POST',
      `${environment.apiHost}/companies/uploadMedia`,
      {
        body,
        reportProgress: true,
        headers: { Authorization: 'Bearer ' + this.token },
        responseType: 'json',
        observe: 'events',
      }
    );
  }

  getUserImages$(): Observable<string[]> {
    return this.http
      .get<{ src: string }[]>(`${environment.apiHost}/companies/getMedias`, {
        headers: { Authorization: 'Bearer ' + this.token },
      })
      .pipe(map((medias) => medias.map((m) => encodeURI(m.src))));
  }

  createHTMLTemplate$(
    emailAndFonts: IEmailAndGoogleFonts,
    url: string = NGB_HOST
  ): Observable<IMjmlServerResponse> {
    return this.http.post<IMjmlServerResponse>(url as string, emailAndFonts);
  }

  getModules(forms: any[]): any[] {
    if (!forms.length)
      return []
    const modules = [
      {
        name: 'QR Code du participant',
        module: new Structure('cols_1', [
          [
            new ImageBlock(
              'https://pz-editor.prezevent.com/assets/QRCODE_TEMPLATE.png',
              {
                width: { value: 100, unit: 'px' },
                height: { value: 100, unit: 'px' },
              }
            ),
          ],
        ]),
      },
      ...forms.map(form => ({
        name: `Form - ${form.name}`,
        module: new Structure('cols_1', [
          [
            new ButtonBlock('Inscription', {
              link: { href: `{{inscrit.subscription_url_${form.id}}}`, target: '_blank' },
            }),
          ],
        ]),
      })),
      {
        name: 'Lien de désabonnement',
        module: new Structure('cols_1', [
          [
            new TextBlock(
              '<p class="ql-align-center"><a href="{{inscrit.optin_url}}">Me désabonner</a></p>',
              { font: { size: 14, weight: 300 } }
            ),
          ],
        ]),
      },
      {
        name: 'Version en ligne',
        module: new Structure('cols_1', [
          [
            new TextBlock(
              '<p class="ql-align-right"><a href="{{inscrit.online_url}}">Voir la version en ligne</a></p>',
              { font: { size: 14, weight: 300 } }
            ),
          ],
        ]),
      },
      {
        name: 'Badge',
        module: new Structure('cols_1', [
          [
            new ButtonBlock('Obtenir mon badge', {
              link: { href: '{{inscrit.url}}', target: '_blank' },
            }),
          ],
        ]),
      },
    ];
    return modules;
  }

  getMergeFields(fields: any[]): string[] {
    const vals = [
      '{{event.name}}',
      '{{event.date}}',
      '{{event.place}}',
      '{{event.logo}}',
      '{{event.website_url}}',
    ];

    fields
      .filter(
        (f) => f.enabled === true && f.id !== 'signature' && f.id !== 'checkin'
      )
      .forEach((field) => vals.push('{{inscrit.' + field.id + '}}'));

    vals.push('{{inscrit.qrcode}}');
    vals.push('{{inscrit.url}}');
    vals.push('{{inscrit.subscription_url}}');
    vals.push('{{inscrit.optin_url}}');
    vals.push('{{inscrit.online_url}}');

    this.fields$.next(vals);
    return vals;
  }

  getHtmlFromMjml(mjml: string): Observable<any> {
    return this.http.post(
      environment.serviceUrl + '/toMjml',
      { mjml },
      {
        // headers: {
        //   Accept: 'text/html, application/xhtml+xml, */*',
        //   'Content-Type': 'application/x-www-form-urlencoded',
        // },
        responseType: 'text',
      }
    );
  }
}
