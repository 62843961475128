import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IpEmailBuilderService } from 'ip-email-builder';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { UserMediaRestApiService } from './user-media-rest-api.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  private ASKCONTENT = 'iframe_ask_for_content_';
  private ASKTOKEN = 'iframe_ask_for_token';
  private ASKFORMS = "iframe_ask_for_forms";
  private ASKFIELDS = 'iframe_ask_for_fields';

  editorEnabled = false;

  campaignId!: string;

  currentEmail = undefined;
  campaignContent: any;
  STORAGE_PATH = 'https://storage.googleapis.com/pz-app-accounts/';

  editorOptions = { theme: 'vs-dark', language: 'html' };
  code = '';

  htmlSource$ = new BehaviorSubject<SafeHtml>('');

  constructor(
    private ngb: IpEmailBuilderService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private userMediaSvc: UserMediaRestApiService
  ) {
    this.route.queryParams
      .pipe(
        filter((params) => params.id !== undefined),
        tap((params) => {
          this.campaignId = params.id;
          window.top.postMessage(`${this.ASKCONTENT}${params.id}`, '*');
        })
      )
      .subscribe();

    window.top.postMessage(`${this.ASKTOKEN}`, '*');
    window.top.postMessage(`${this.ASKFIELDS}`, '*');
    window.top.postMessage(`${this.ASKFORMS}`, '*');

    window.onmessage = (e: any) => {
      if (e.data && typeof e.data === 'object' && e.data.topic === 'token') {
        this.userMediaSvc.token = e.data.token;
      } else if (e.data && typeof e.data === 'object' && e.data.topic === 'forms') {
        this.userMediaSvc.forms$.next(e.data.forms);
      }
      else if (
        e.data &&
        typeof e.data === 'object' &&
        e.data.topic === 'fields'
      ) {
        this.userMediaSvc.getMergeFields(Object.values(e.data.fields));
      } else if (
        e.data &&
        typeof e.data === 'object' &&
        e.data.topic === 'campaign'
      ) {
        this.initCampaignContent(e.data.content);
      }
    };
  }

  ngOnInit(): void {
    this.ngb.onSave$
      .pipe(
        tap(([json, html, mjml]) =>
          window.top.postMessage({ json, html, mjml }, '*')
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({ next: () => console.log('Email and Template saved.') });
  }

  initCampaignContent(content: any): void {
    this.campaignContent = content;
    if (this.campaignContent.json && this.campaignContent.json !== '') {
      this.editorEnabled = true;
      this.currentEmail = JSON.parse(this.campaignContent.json);
    } else {
      this.editorEnabled = false;
      this.code =
        this.campaignContent.mjml && this.campaignContent.mjml !== ''
          ? this.campaignContent.mjml
          : this.campaignContent.html;
      if (this.campaignContent.mjml) {
        this.setCampaignHtml(this.campaignContent.mjml).subscribe();
      }
    }
  }

  updateCode(mjml: string): void {
    this.setCampaignHtml(mjml).subscribe();
  }

  setCampaignHtml(mjml: string): Observable<any> {
    return this.userMediaSvc.getHtmlFromMjml(mjml).pipe(
      tap((result) => {
        this.campaignContent.html = result;
        this.htmlSource$.next(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            'data:text/html;charset=utf-8,' + escape(result)
          )
        );
      })
    );
  }

  saveEmail(): void {
    const isMjml = /^\s*<mjml/gi.test(this.code);
    window.top.postMessage(
      {
        json: '',
        html: isMjml ? this.campaignContent.html : this.code,
        mjml: isMjml ? this.code : '',
      },
      '*'
    );
  }

  public getCampaignUrl(): string | SafeUrl {
    const url = `${environment.serviceUrl}/campaign/${this.campaignId}`;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
