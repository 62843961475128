import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IpEmailBuilderModule, IpUserRestApiService } from 'ip-email-builder';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserMediaRestApiService } from './user-media-rest-api.service';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { FormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    HttpClientModule,
    MonacoEditorModule.forRoot(),
    IpEmailBuilderModule.withConfig({
      xApiKey: environment.emailBuilderKey,
      uploadImagePath: environment.apiHost + '/company/uploadMedia',
      uploadImageName: 'file',
    }),
  ],
  providers: [
    UserMediaRestApiService,
    { provide: IpUserRestApiService, useExisting: UserMediaRestApiService },
    // {
    //   provide: ImageUploader, // This is default service
    //   useClass: UploadImageService, // This is your own service
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
